import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './translations/en.json';
import esTranslation from './translations/es.json';

let lng = 'es';

if (typeof window !== 'undefined') {
  lng = localStorage.getItem('lng') || 'es';
}

i18n.use(initReactI18next).init({
  debug: false,
  lng,
  resources: {
    en: {
      translation: enTranslation,
    },
    es: {
      translation: esTranslation,
    },
  },
});

export default i18n;
