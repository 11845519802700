export function saveLng(lng) {
  localStorage.setItem('lng', lng);
}

export function getLng() {
  let lng = 'es';

  if (typeof window !== 'undefined') {
    lng = localStorage.getItem('lng') || 'es';
  }

  return lng;
}
