import React from 'react';
import { useTranslation } from 'react-i18next';

import logoImg from '../../../images/logo_negro.png';
import twitterImg from '../../../images/gorjeo.png';
import facebookImg from '../../../images/facebook.png';
import instagramImg from '../../../images/instagram.png';
import youtubeImg from '../../../images/youtube.png';
import whatsappImg from '../../../images/whatsapp.png';
import appleImg from '../../../images/applechat.png';
import './footer.css';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <div className="row">
        <div className="col-3">
          <img alt="ebox code logo" className="logo-footer" src={logoImg} />

          <p>
            {t('footer.description')}
          </p>
        </div>
        <div className="col-3">
          <ul className="col center footer-menu">
            <li>
              <span>{t('footer.pages')}</span>
            </li>
            <li>
              <a aria-current="page" href="/">{t('nav.home')}</a>
            </li>
            <li>
              <a href="/nosotros">{t('nav.we')}</a>
            </li>
            <li>
              <a href="/#servicios">{t('nav.services')}</a>
            </li>
            <li>
              <a href="/contacto">{t('nav.contact')}</a>
            </li>
          </ul>
        </div>
        <div className="col-3">
          <strong>{t('footer.contact')}</strong>

          <p>{t('footer.contactDir')}</p>

          <div className="row social-link">
            <a
              href="https://instagram.com/eboxcode?igshid=hp99bggr500n"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="instagram" src={instagramImg} />
            </a>
            <a
              href="https://www.facebook.com/eboxcode/"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="facebook" src={facebookImg} />
            </a>
            <a
              href="https://twitter.com/eboxcode?s=11"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="twitter" src={twitterImg} />
            </a>
            <a
              href="https://wa.me/526672297285?text=Hola"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="whatsapp" src={whatsappImg} />
            </a>
            <a
              href="https://youtube.com/channel/UCnmudRoryd5r9nsUT1Jy0jQ"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="youtube" src={youtubeImg} />
            </a>
            <a
              href="https://apple.co/3vNItP8"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="apple" src={appleImg} />
            </a>
          </div>
        </div>
      </div>
      <div className="row footer-link">
        <a href="/avisodeprivacidad">{t('footer.polict')}</a>

        <span>{t('footer.ebox')}</span>
      </div>
    </footer>
  );
};

export default Footer;
