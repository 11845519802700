import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getLng, saveLng } from '../../../utils/sotre';

import logoBlackImg from '../../../images/logo_negro.png';
import logoWhiteImg from '../../../images/logo_blanco.png';
import esIcon from '../../../assets/icons/es-flag.svg';
import enIcon from '../../../assets/icons/en-flag.svg';
import './nav.css';

export default function Nav({ whiteTheme = false }) {
  const [showMenu, setShowMenu] = useState(null);
  const { t, i18n } = useTranslation();
  const lng = getLng();

  function onChangeLng({ target }) {
    const { value } = target;

    i18n.changeLanguage(value);
    saveLng(value);
  }

  function toggleMenu() {
    if (showMenu) {
      setShowMenu(null);
    } else {
      setShowMenu('menu-show');
    }
  }

  function onTouchOutMenu() {
    setShowMenu(null);
  }

  function onClickMenu() {
    toggleMenu();
  }

  return (
    <nav className={whiteTheme ? 'nav-over' : ''}>
      <a href="/">
        <img
          alt="ebox code"
          className="logo-black"
          src={whiteTheme ? logoWhiteImg : logoBlackImg}
        />
      </a>

      {/*  TODO: idioma ingles no disponible de momento */}
      {/* <select className="lng-select" onChange={onChangeLng} value={lng}>
        <option className="option-es" value="en">EN</option>
        <option value="es">ES</option>
      </select> */}

      <div
        className={`menu ${showMenu || ''}`}
        onClick={onTouchOutMenu}
        onKeyDown={null}
        role="button"
        tabIndex={-1}
      >
        <ul className={(whiteTheme && !showMenu) ? 'ul-white' : ''}>
          <li className="">
            <a className="" onClick={onClickMenu} aria-current="page" href="/">{t('nav.home')}</a>
          </li>
          <li className="">
            <a className="" onClick={onClickMenu} href="/nosotros">{t('nav.we')}</a>
          </li>
          <li className="">
            <a className="" onClick={onClickMenu} href="/#servicios">{t('nav.services')}</a>
          </li>
          <li className="">
            <a className="" onClick={onClickMenu} href="/contacto">{t('nav.contact')}</a>
          </li>
        </ul>

      </div>

      <div
        aria-expanded={!!showMenu}
        className={`menu-bar ${showMenu ? 'animate-button' : ''}`}
        onClick={toggleMenu}
        onKeyPress={toggleMenu}
        role="button"
        tabIndex={0}
      >
        <div className={`one ${whiteTheme ? 'line-white' : ''}`} />
        <div className={`two ${whiteTheme ? 'line-white' : ''}`} />
        <div className={`three ${whiteTheme ? 'line-white' : ''}`} />
      </div>
    </nav>
  );
}
