import React from 'react';
import { I18nextProvider } from 'react-i18next';

import i18n from '../../i18n';

import SEO from '../common/seo';
import Nav from '../common/nav';
import Footer from '../common/footer';

import './font.css';
import './colors.css';
import './layout.css';
import '../common/loading.css';

const Layout = ({ children, whiteTheme = false }) => (
  <I18nextProvider i18n={i18n}>
    <SEO />

    <Nav whiteTheme={whiteTheme} />

    <main>
      {children}

      <Footer />
    </main>
  </I18nextProvider>
);

export default Layout;
