module.exports = {
  defaultTitle: 'Ebox Code',
  logo: 'favicon.icon',
  author: '',
  url: 'https://eboxcode.com',
  legalName: 'Marisol Sanchez',
  defaultDescription: 'Somos un equipo multidisciplinario capaz de crear herramientas digitales y experiencias únicas.',
  socialLinks: {
    twitter: 'https://twitter.com/eboxcode?s=11',
    instagram: 'https://instagram.com/eboxcode?igshid=hp99bggr500n',
    youtube: 'https://youtube.com/channel/UCnmudRoryd5r9nsUT1Jy0jQ',
    facebook: 'https://www.facebook.com/eboxcode/',
  },
  googleAnalyticsID: '',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    facebook: 'eboxcode',
    twitter: '@eboxcode',
  },
  address: {
    city: 'Sinaloa',
    region: 'Culiacan',
    country: 'Sinaloa',
    zipCode: '80040',
  },
  contact: {
    email: 'hola@eboxcode.com',
    phone: '(+52) 667 764 6261',
  },
  foundingDate: '2021',
};
